<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Almacén Bonos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Almancén Bonos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row justify-content-between">
                  <div
                    class="btn-group"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.almacenBonos.exportExcel'
                      )
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-gradient-secondary shadow"
                      data-toggle="modal"
                      data-target="#modal-form-import"
                      style="cursor: pointer"
                      @click="limpiarExcel"
                    >
                      Importar Excel
                      <i class="fas fa-file-import"></i>
                    </button>
                  </div>
                  <div class="btn-group">
                    <div
                      class="btn-group float-right"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.almacenBonos.moverBonos'
                        )
                      "
                    >
                      <button
                        type="button"
                        class="btn bg-gradient-info shadow"
                        data-toggle="modal"
                        data-target="#modal_aside_right"
                        style="cursor: pointer"
                        @click="limpiarModal"
                      >
                        Movimiento Bono
                        <i class="fas fa-people-carry"></i>
                      </button>
                    </div>
                  </div>
                  <div class="btn-group shadow">
                    <button
                      type="button"
                      class="btn bg-gradient-success btn-md"
                      data-toggle="modal"
                      data-target="#modal_informe_excel"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.almacenBonos.exportExcelBonos'
                        )
                      "
                      @click="$refs.BonoExportExcel.limpiarModal()"
                    >
                      <i class="far fa-file-excel"></i>
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-primary btn-md"
                      style="cursor: pointer"
                      @click="getIndex()"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-info"
                      @click="getIndex()"
                    >
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-danger"
                      @click="limpiar()"
                    >
                      <i class="fas fa-broom"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>Código Bono</label>
                    <input
                      type="text"
                      v-model="filtros.codigo"
                      label="id"
                      class="form-control form-control-sm shadow-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Tipo Bonos</label>
                    <select
                      class="form-control form-control-sm shadow-sm"
                      v-model="filtros.tipo_bono"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.tipo_bonos"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Sitio</label>
                    <v-select
                      v-model="sitio"
                      placeholder="Sitio"
                      label="nombre"
                      class="form-control form-control-sm shadow-sm p-0"
                      :options="listasForms.sitios"
                      :filterable="false"
                      @search="buscarSitios"
                      @input="seleccionarSitio()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="vehiculo">Vehículo</label>
                    <v-select
                      v-model="vehiculo"
                      placeholder="Placa"
                      label="placa"
                      class="form-control form-control-sm shadow-sm p-0"
                      :options="listasForms.vehiculos"
                      :filterable="false"
                      @search="buscarVehiculos"
                      @input="seleccionarVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Guía</label>
                    <input
                      type="text"
                      v-model="filtros.guia_id"
                      label="id"
                      class="form-control form-control-sm shadow-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Viaje</label>
                    <input
                      type="text"
                      v-model="filtros.viaje_id"
                      label="id"
                      class="form-control form-control-sm shadow-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Conductor</label>
                    <v-select
                      v-model="conductor"
                      placeholder="Conductor"
                      label="nombre"
                      class="form-control form-control-sm shadow-sm p-0"
                      :options="listasForms.conductores"
                      :filterable="false"
                      @search="buscarConductores"
                      @input="seleccionarconductor()"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-bordered table-striped table-hover table-sm shadow"
                      style="font-size: 0.8em"
                    >
                      <thead class="bg-gradient-dark">
                        <tr>
                          <!-- <th></th> -->
                          <th class="text-center">Código</th>
                          <th class="text-center">Tipo Bono</th>
                          <th class="text-center">Sitio</th>
                          <th class="text-center">Vehículo</th>
                          <th class="text-center">Guía</th>
                          <th class="text-center">Viaje</th>
                          <th class="text-center">Conductor</th>
                          <th class="text-center">Monto</th>
                          <th class="text-center">Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in bonos.data" :key="item.id">
                          <td class="text-center">{{ item.codigo }}</td>
                          <td class="text-center">
                            {{ item.nTipoBono }}
                          </td>
                          <td class="text-center">
                            {{ item.sitio ? item.sitio.nombre : "" }}
                          </td>
                          <td class="text-center" v-if="item.viaje">
                            {{
                              item.viaje.vehiculo_id
                                ? item.viaje.vehiculo.placa
                                : ""
                            }}
                          </td>
                          <td class="text-center" v-else></td>
                          <td class="text-center" v-if="item.viaje">
                            {{ item.viaje.guia_id }}
                          </td>
                          <td class="text-center" v-else></td>

                          <td class="text-center" v-if="item.viaje">
                            {{ item.viaje.id }}
                          </td>
                          <td class="text-center" v-else></td>
                          <td class="text-center" v-if="item.viaje">
                            {{
                              item.viaje.conductor_id
                                ? item.viaje.conductor.nombres +
                                  " " +
                                  item.viaje.conductor.apellidos
                                : ""
                            }}
                          </td>
                          <td class="text-center" v-else></td>
                          <td
                            class="text-center text-uppercase font-weight-bold"
                          >
                            {{
                              item.monto
                                ? "$ " +
                                  parseFloat(item.monto).toLocaleString("es-ES")
                                : "---"
                            }}
                          </td>
                          <td class="text-center">
                            <span
                              class="badge  shadow"
                              :class="
                                item.estado == 1
                                  ? 'badge badge-pill badge-warning'
                                  : item.estado == 2
                                  ? 'badge badge-pill badge-success'
                                  : ''
                              "
                            >
                              {{ item.nEstado }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div
                  class="float-left"
                  v-if="bonos.data && bonos.data.length > 0"
                >
                  <p>
                    Mostrando del <b>{{ bonos.from }}</b> al
                    <b>{{ bonos.to }}</b> de un total:
                    <b>{{ bonos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  class="float-right shadow"
                  :data="bonos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <GestionBonoExport ref="GestionBonoExport" />
    <BonoImportExcel ref="BonoImportExcel" />
    <BonoMovimiento ref="BonoMovimiento" />
    <BonoExportExcel ref="BonoExportExcel" />
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import GestionBonoExport from "./GestionBonoExport.vue";
import BonoImportExcel from "./BonoImportExcel";
import BonoExportExcel from "./BonoExportExcel";
import BonoMovimiento from "./BonoMovimiento";
export default {
  name: "AlmacenBonoIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    GestionBonoExport,
    BonoImportExcel,
    BonoMovimiento,
    BonoExportExcel,
  },
  data() {
    return {
      page: 1,
      cargando: false,
      bonos: {},
      filtros: {},
      bono: null,
      transportadora: null,
      ruta: null,
      sitio: null,
      vehiculo: null,
      conductor: null,
      sitio_cargue: null,
      sitio_descargue: null,
      listasForms: {
        estados: [],
        sitios: [],
        vehiculos: [],
        conductores: [],
        empresas: [],
        tipo_rutas: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      formDoc: {},
      fileDoc: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    formDoc: {
      fecha_pago: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.page = page;
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/almacenBonos?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.cargando = false;
          this.bonos = response.data;
        });
    },

    limpiar() {
      this.filtros.codigo = null;
      this.filtros.tipo_bono = null;
      this.sitio = null;
      this.filtros.sitio_id = null;
      this.filtros.viaje_id = null;
      this.filtros.guia_id = null;
      this.conductor = null;
      this.filtros.conductor_id = null;
      this.vehiculo = null;
      this.filtros.vehiculo_id = null;
      this.getIndex();
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitios = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            loading(false);
          });
      }
    },

    seleccionarconductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
      this.getIndex();
    },

    seleccionarSitio() {
      if (this.sitio != null) {
        this.filtros.sitio_id = this.sitio.id;
      } else {
        this.filtros.sitio_id = null;
      }
      this.getIndex();
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
      this.getIndex();
    },

    getTipoBonos() {
      axios.get("/api/lista/194").then((response) => {
        this.listasForms.tipo_bonos = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/198").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    buscarVehiculos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url, {})
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
            loading(false);
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            loading(false);
          });
      }
    },

    buscarConductores(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarModal() {
      this.$refs.BonoMovimiento.limpiarModal();
    },

    limpiarExcel(){
      this.$refs.BonoImportExcel.limpiarModal();
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoBonos();
    this.getEstados();
  },
};
</script>
